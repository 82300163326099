import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function removeAllError(form: FormGroup | FormArray): void {
  Object.keys(form?.controls || {}).forEach((controlName: string) => {
    const control: AbstractControl<any> = form.get(controlName);
    if (control instanceof FormGroup) {
      removeAllError(control);
    } else if (control instanceof FormArray) {
      control.controls.forEach((formGroup: AbstractControl<any>) => this.removeAllError(<FormGroup>formGroup));
    } else {
      control.setErrors(null);
    }
  });
}
